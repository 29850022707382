import { useIntl } from 'react-intl'

/**
 * Hotel search specific translations
 */
export function useTranslations() {
  const intl = useIntl()
  const dateLabel = {
    checkIn: intl.formatMessage({ id: 'check-in', defaultMessage: 'Check-in' }),
    checkOut: intl.formatMessage({ id: 'check-out', defaultMessage: 'Check-out' }),
  }
  const t = {
    search: intl.formatMessage({ id: 'search-label', defaultMessage: 'Searching...' }),
    noResults: intl.formatMessage({ id: 'no-results', defaultMessage: 'No results found for ' }),
    destinationLabel: intl.formatMessage({
      id: 'destination-label',
      defaultMessage: 'Destination',
    }),
  }

  return { dateLabel, t }
}
