import { Dispatch } from 'react'
import { SystemStyleObject } from '@chakra-ui/react'
import { RoomPickerActionType } from './TravelerRoomPicker.constants'

export type Room = {
  roomNumber: number
  adults: number
  children: number
  childrenAges?: Array<ChildAge>
}

export type QueryStringRoom = {
  roomNumber: string
  adults: string
  children: string
  childrenAges?: Array<QueryStringChildAge>
}

export type Action = {
  type: RoomPickerActionType
  payload: Pick<Room, 'roomNumber'> & Partial<Room & ChildAge>
}

export type TravelerRoomPickerProps = {
  rooms: Room[]
  dispatch: Dispatch<Action>
  sx?: SystemStyleObject
  errors?: string[] | string
}

export type TravelerRoom = {
  room: Room
  dispatch: Dispatch<Action>
  totalTravelers: number
  errors?: string | string[]
}

export type ChildAge = {
  child: string
  age: number
}

export type QueryStringChildAge = {
  child: string
  age: string
}

export enum TravelerRoomPickerModalSizes {
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  lg = 'lg',
  xl = 'xl',
  full = 'full',
}
