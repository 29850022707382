// React
import { ReactElement } from 'react'

// Components
import { chakra, Button, useMultiStyleConfig, VStack } from '@chakra-ui/react'
import TravelerQuantityPicker from '~/components/shared/TravelerQuantityPicker/TravelerQuantityPicker'
import { ChildrenAgePicker } from '~/components/shared/ChildrenAgePicker'

// Types
import { TravelerRoom as TravelerRoomProps } from '../TravelerRoomPicker.types'
import {
  REMOVE_ROOM,
  SET_TRAVELERS,
  MAX_TRAVELERS_PER_BOOKING,
  MAX_TRAVELERS_PER_ROOM,
  MAX_CHILDREN_PER_ROOM,
  MAX_ADULTS_PER_ROOM,
  SET_CHILD_AGE,
} from '../TravelerRoomPicker.constants'

// Hooks
import { useTranslations } from './TravelerRoom.translations'

export const TravelerRoom = ({
  room,
  dispatch,
  totalTravelers,
  errors,
}: TravelerRoomProps): ReactElement => {
  const styles = useMultiStyleConfig('TravelerRoom', {})
  const { travelerRoomTranslations: t } = useTranslations()

  const AdultsLabel = <chakra.span __css={styles.travellerLabel}>{t.adults}</chakra.span>
  const ChildrenLabel = (
    <VStack>
      <chakra.span sx={styles.travellerLabel}>{t.children}</chakra.span>
      <chakra.span __css={styles.agedLabel}>{t.childAgeRange}</chakra.span>
    </VStack>
  )

  const { roomNumber, adults, children } = room

  const travelers = {
    adult: {
      limits: {
        minCount: 1,
        maxCount: totalTravelers < MAX_TRAVELERS_PER_BOOKING ? MAX_ADULTS_PER_ROOM : 1,
      },
      quantity: adults,
      label: AdultsLabel,
      isVisible: true,
    },
    child: {
      limits: {
        minCount: 0,
        maxCount: totalTravelers < MAX_TRAVELERS_PER_BOOKING ? MAX_CHILDREN_PER_ROOM : 0,
      },
      quantity: children,
      label: ChildrenLabel,
      isVisible: true,
    },
    infant: { isVisible: false },
    senior: { isVisible: false },
  }

  return (
    <div
      data-testid="traveller-room-section"
      tabIndex={-1}
      role="listitem"
      aria-label="traveler room"
    >
      <chakra.div __css={styles.roomNumberContainer}>
        <chakra.span __css={styles.roomNumberTitle}>{`${t.room} ${roomNumber}`}</chakra.span>
        {roomNumber !== 1 && (
          <Button
            type="button"
            variant="link"
            padding="0 6px"
            onClick={() => dispatch({ type: REMOVE_ROOM, payload: { roomNumber } })}
          >
            {t.remove}
          </Button>
        )}
      </chakra.div>
      <TravelerQuantityPicker
        data-testid="traveler-quantity-picker"
        maxTravelers={MAX_TRAVELERS_PER_BOOKING}
        maxTravelersPerRoom={MAX_TRAVELERS_PER_ROOM}
        totalTravelerCount={totalTravelers}
        travelers={travelers}
        onChange={({ adult: { quantity: adultQuantity }, child: { quantity: childQuantity } }) =>
          dispatch({
            type: SET_TRAVELERS,
            payload: {
              roomNumber,
              adults: adultQuantity,
              children: childQuantity,
            },
          })
        }
      />
      <chakra.div __css={styles.childrenAgePickerContainer}>
        <ChildrenAgePicker
          childrenAges={room.childrenAges}
          errors={errors}
          handleSelect={({ child, age }) => {
            dispatch({ type: SET_CHILD_AGE, payload: { roomNumber, child, age } })
          }}
        />
      </chakra.div>
    </div>
  )
}

export default TravelerRoom
