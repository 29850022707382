import useIntl from '~/hooks/useIntl/useIntl'

export const useTranslations = () => {
  const intl = useIntl()

  const travelerRoomTranslations = {
    children: intl.formatMessage({
      id: 'children-label',
      defaultMessage: 'Children',
    }),
    adults: intl.formatMessage({
      id: 'adults-label',
      defaultMessage: 'Adults',
    }),
    childAgeRange: intl.formatMessage({
      id: 'child-age-range',
      defaultMessage: 'Aged 0 to 17',
    }),
    room: intl.formatMessage({
      id: 'room',
      defaultMessage: 'Room',
    }),
    remove: intl.formatMessage({
      id: 'remove',
      defaultMessage: 'Remove',
    }),
  }

  return { travelerRoomTranslations }
}
