import useIntl from '~/hooks/useIntl/useIntl'
import { MAX_TRAVELERS_PER_BOOKING } from './TravelerRoomPicker.constants'
import { Room } from './TravelerRoomPicker.types'

export type TravelerRoomPickerTranslationsProps = {
  totalChildren?: number
  totalAdults?: number
  rooms?: Room[]
}

export const useTranslations = ({
  totalChildren,
  totalAdults,
  rooms,
}: TravelerRoomPickerTranslationsProps) => {
  const intl = useIntl()

  const travelerRoomPickerTranslations = {
    addAnotherRoom: intl.formatMessage({
      id: 'addAnotherRoom',
      defaultMessage: '+ Add another room',
    }),
    children: intl.formatMessage(
      {
        id: 'children-count',
        defaultMessage: '{count} {count, plural, one {child} other {children}}',
      },
      { count: totalChildren }
    ),
    adults: intl.formatMessage(
      {
        id: 'adults-count',
        defaultMessage: '{count} {count, plural, one {adult} other {adults}}',
      },
      { count: totalAdults }
    ),
    rooms: intl.formatMessage(
      { id: 'rooms', defaultMessage: '{count} {count, plural, one {room} other {rooms}}' },
      { count: rooms?.length }
    ),
    maxTravelerCount: intl.formatMessage(
      {
        id: 'max-traveler-count',
        defaultMessage: 'You may add up to {count} total travellers',
      },
      { count: MAX_TRAVELERS_PER_BOOKING }
    ),
    done: intl.formatMessage({
      id: 'done',
      defaultMessage: 'Done',
    }),
    travelers: intl.formatMessage({
      id: 'travellers',
      defaultMessage: 'Travellers',
    }),
  }

  return { travelerRoomPickerTranslations }
}
