import { ReactElement, useCallback, useRef, useState } from 'react'
import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useMediaQuery,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Button,
  useMultiStyleConfig,
} from '@chakra-ui/react'
import { ChakraInput } from '@reward-platform/lift/components'
import { defaultIconProps } from '@reward-platform/lift/components/ChakraListBox'
import { deviceSizes } from '@reward-platform/lift/theme/deviceSizes'
import * as types from './TravelerRoomPicker.constants'
import { TravelerRoom } from './TravelerRoom'
import { MAX_TRAVELERS_PER_BOOKING } from './TravelerRoomPicker.constants'

import { TravelerRoomPickerModalSizes, TravelerRoomPickerProps } from './TravelerRoomPicker.types'
import { useTranslations } from './TravelerRoomPicker.translations'

export const TravelerRoomPicker = ({
  rooms,
  dispatch,
  errors,
  sx,
}: TravelerRoomPickerProps): ReactElement => {
  const styles = useMultiStyleConfig('TravelerRoomPicker', {})
  const [isDesktop] = useMediaQuery(`(min-width: ${deviceSizes.medium})`)
  const roomsRef = useRef<HTMLDivElement>(null)

  const totalAdults = rooms.reduce((sum, { adults }: { adults: number }) => sum + adults, 0)
  const totalChildren = rooms.reduce((sum, { children }: { children: number }) => sum + children, 0)

  const { travelerRoomPickerTranslations: t } = useTranslations({
    rooms,
    totalAdults,
    totalChildren,
  })

  const hasChildren = totalChildren > 0 ? `${t.children}, ` : ''

  const placeholder = `${t.adults}, ${hasChildren}${t.rooms}`

  // HOTEL: Max guests per room - 7, Max guests per booking - 14
  const ableToAddRoom = totalAdults + totalChildren < MAX_TRAVELERS_PER_BOOKING && rooms.length < 4

  const { isOpen, onOpen, onClose } = useDisclosure()
  const [size, setSize] = useState(TravelerRoomPickerModalSizes.full)

  const handleSizeClick = (newSize: TravelerRoomPickerModalSizes) => {
    setSize(newSize)
    onOpen()
  }

  const focusLastRoom = useCallback(() => {
    setTimeout(() => {
      const lastRoom = roomsRef.current?.lastChild as HTMLElement
      lastRoom?.focus()
    }, 100)
  }, [])

  const Picker = (
    <>
      <Box __css={styles.maxTravellerBadge} sx={sx}>
        {t.maxTravelerCount}
      </Box>
      <Box ref={roomsRef} role="list">
        {rooms.map((room) => (
          <TravelerRoom
            data-testid="traveller-room-section"
            key={`${room.roomNumber}-${room.adults}-${room.children}-${room.childrenAges}`}
            room={room}
            dispatch={dispatch}
            totalTravelers={totalAdults + totalChildren}
            errors={errors}
          />
        ))}
      </Box>
      {ableToAddRoom && (
        <Box sx={styles.addRoomButtonContainer}>
          <Button
            type="button"
            variant="link"
            onClick={() => {
              dispatch({
                type: types.ADD_ROOM,
                payload: { roomNumber: rooms.length + 1 },
              })
              focusLastRoom()
            }}
          >
            {t.addAnotherRoom}
          </Button>
        </Box>
      )}
    </>
  )

  const infoInput = (
    <ChakraInput
      sx={sx}
      onClick={() => handleSizeClick(size)}
      onKeyDown={(keyEvent) => {
        if (keyEvent.key === 'Enter') {
          keyEvent.preventDefault()
          handleSizeClick(size)
        }
      }}
      key={size}
      note-variant="align-left"
      {...defaultIconProps}
      name="Traveller Room Picker"
      label={t.travelers}
      value={placeholder}
      minW="248px"
      id="dropdown-traveller-input"
      role="combobox"
      data-testid="dropdown-traveller-input"
      error={Array.isArray(errors) ? errors[0] : errors}
    />
  )

  return (
    <>
      {isDesktop ? (
        <Popover placement="bottom" isOpen={isOpen} onClose={onClose}>
          <PopoverTrigger>{infoInput}</PopoverTrigger>
          <PopoverContent sx={styles.popOverContent}>
            <PopoverBody p={4} aria-label="traveler room picker">
              {Picker}
              <Button size="sm" sx={styles.popOverButton} onClick={onClose}>
                {t.done}
              </Button>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      ) : null}

      {!isDesktop ? (
        <>
          {infoInput}
          <Modal onClose={onClose} size={size} isOpen={isOpen}>
            <ModalOverlay />
            <ModalContent>
              <Box p={4}>
                <ModalCloseButton sx={styles.modalCloseButton} />
                <ModalHeader as="h4" sx={styles.modalHeader}>
                  {t.travelers}
                </ModalHeader>
                <ModalBody p={4} w="full" maxW="sm">
                  {Picker}
                  <Button onClick={onClose} mt={8} w="full">
                    {t.done}
                  </Button>
                </ModalBody>
              </Box>
            </ModalContent>
          </Modal>
        </>
      ) : null}
    </>
  )
}

export default TravelerRoomPicker
