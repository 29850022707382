import { useRouter } from 'next/router'
import { stringify } from 'qs'
import { addHours, format, isSameMonth } from 'date-fns'
import { Box, Button, Icon, chakra, useMultiStyleConfig } from '@chakra-ui/react'
import { Dates } from '@reward-platform/ancillaries-schemas/common/query-params'
import { IconName } from '@reward-platform/ancillaries-schemas/common'
import { Destination } from '@reward-platform/ancillaries-schemas/location'
import { IconSizes } from '@reward-platform/lift/theme/iconSize'
import useIntl from '~/hooks/useIntl/useIntl'
import { convertIconSetToChakraSVG } from '~/utils/Icon.utils'
import { pushEventToDataLayer } from '~/utils/googleTagManager'
import { Room } from '../SearchHotelForm/TravelerRoomPicker/TravelerRoomPicker.types'

export type RecentSearch = {
  destination: Destination
  dates: Dates
  rooms: Room[]
  page: number
  searchType: string
}

type RecentSearches = {
  data: RecentSearch[]
}

export const LOCAL_STORAGE_HOTELS_RECENT_SEARCHES = 'hotels_recent_searches'
const MAX_COUNT_RECENT_SEARCHES = 4

const filterValidSearchDateTime = (entry: Dates) => {
  const minStartDateTime = addHours(new Date(), 27)
  return (
    new Date(entry.startDateTime) > minStartDateTime &&
    new Date(entry.endDateTime) > new Date(entry.startDateTime)
  )
}

export const getRecentSearchesFromLocalStorage = (): RecentSearch[] => {
  const data = localStorage.getItem(LOCAL_STORAGE_HOTELS_RECENT_SEARCHES)

  if (!data) {
    return []
  }

  const parsedData = JSON.parse(data) as RecentSearches['data']

  return parsedData.filter((x) => filterValidSearchDateTime(x.dates))
}

export const addSearchToLocalStorage = (data: RecentSearch) => {
  const currentSearches = getRecentSearchesFromLocalStorage()

  const foundIndex = currentSearches.findIndex((x) => JSON.stringify(x) === JSON.stringify(data))
  if (foundIndex > -1) {
    currentSearches.splice(foundIndex, 1)
  }

  if (currentSearches.length > MAX_COUNT_RECENT_SEARCHES - 1) {
    currentSearches.shift()
  }
  currentSearches.push(data)

  localStorage.setItem(LOCAL_STORAGE_HOTELS_RECENT_SEARCHES, JSON.stringify(currentSearches))
}

export default function RecentSearches(props: RecentSearches) {
  const { data } = props
  const recentSearches = [...data].reverse()
  const styles = useMultiStyleConfig('RecentSearches')
  const { push } = useRouter()
  const intl = useIntl()

  const t = {
    recentSearches: intl.formatMessage({
      id: 'recent-searches',
      defaultMessage: 'Recent Searches',
    }),
  }

  const formatTravellers = (rooms: Room[]) => {
    const adultCount = rooms.reduce((prev, crt) => prev + crt.adults, 0)
    const childCount = rooms.reduce((prev, crt) => prev + crt.children, 0)
    const travellersCount = adultCount + childCount

    return ` ${travellersCount} traveller${travellersCount === 1 ? '' : 's'}, ${rooms.length} room${
      rooms.length === 1 ? '' : 's'
    }`
  }

  const formatDates = (startDateTime: string, endDateTime: string) => {
    const startDate = new Date(startDateTime)
    const endDate = new Date(endDateTime)
    if (isSameMonth(startDate, endDate)) {
      return `${format(startDate, 'dd')} - ${format(endDate, 'dd LLL')}, `
    }
    return `${format(startDate, 'dd LLL')} - ${format(endDate, 'dd LLL')}, `
  }

  const computeSearch = (search: RecentSearch) => {
    pushEventToDataLayer('navigation', {
      nav_element: 'hotels recent searches',
      nav_type: 'cta',
      nav_action: `${search.destination.fullName} | ${formatDates(
        search.dates.startDateTime,
        search.dates.endDateTime
      )} | ${formatTravellers(search.rooms)}`,
      referrer: document.referrer,
      departure_date: format(new Date(search.dates.endDateTime), 'yyyy-MM-dd'),
      arrival_date: format(new Date(search.dates.startDateTime), 'yyyy-MM-dd'),
      origin: 'not_applicable',
      destination: search.destination.fullName,
    })

    push({
      pathname: '/hotels/results',
      query: stringify(search),
    })

    addSearchToLocalStorage(search)
  }

  return (
    <Box __css={styles.container}>
      <chakra.h4>{t.recentSearches}</chakra.h4>
      {recentSearches.map((search) => (
        <Button
          onClick={() => computeSearch(search)}
          sx={styles.item}
          variant="link"
          key={JSON.stringify(search)}
          data-testid="recent-search"
        >
          <Icon as={convertIconSetToChakraSVG(IconName.Enum.clock)} boxSize={IconSizes.md} />
          <Box>
            <chakra.p>{search.destination.name}</chakra.p>
            <chakra.p>{search.destination.fullName}</chakra.p>
            <chakra.p>
              {formatDates(search.dates.startDateTime, search.dates.endDateTime)}
              {formatTravellers(search.rooms)}
            </chakra.p>
          </Box>
        </Button>
      ))}
    </Box>
  )
}
