import { z } from 'zod'
import { DatesSchema, ParamsSchema } from '../../common/query-params'

export const RoomSearchParamSchema = z.object({
  adults: z.string().min(1),
  roomNumber: z.string().min(1),
  children: z.string().min(1),
  childrenAges: z.array(z.object({ child: z.string(), age: z.string() })).optional(),
})

export const HotelShoppingFilterSchema = z.object({
  description: z.string().optional(),
  guestRating: z.string().optional(),
  starRatings: z.array(z.string()).optional(),
  propertyTypes: z.array(z.string()).optional(),
  sortBy: z.string().optional(),
  orderBy: z.string().optional(),
})

const HotelDestinationSchema = z.object({
  id: z.string().min(1),
  type: z.string().min(1),
  fullName: z.string().min(1),
  name: z.string().min(1),
})

export const HotelSearchParamsSchema = ParamsSchema.extend({
  rooms: z.array(RoomSearchParamSchema),
  destination: HotelDestinationSchema,
  shoppingFilter: z
    .object({
      hotelShoppingFilter: HotelShoppingFilterSchema.optional(),
    })
    .optional(),
  balance: z.coerce.number().optional(),
  scrollRef: z.string().optional(),
})

export const HotelDetailsParamsSchema = z.object({
  rooms: z.array(RoomSearchParamSchema),
  bundleId: z.string().min(1),
  recommendationId: z.string().min(1),
  correlationId: z.string().min(1),
  searchType: z.string(),
  destination: HotelDestinationSchema,
  dates: DatesSchema,
  duration: z.string(),
  balance: z.coerce.number().optional(),
  prevQueryString: z.string().optional(),
})

export type RoomSearchParam = z.infer<typeof RoomSearchParamSchema>
export type HotelSearchParams = z.infer<typeof HotelSearchParamsSchema>
export type HotelDetailsParams = z.infer<typeof HotelDetailsParamsSchema>
export type HotelShoppingFilter = z.infer<typeof HotelShoppingFilterSchema>
