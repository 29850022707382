import { updateAgeOfChild, updateChildrenAges } from '~/components/shared/ChildrenAgePicker/utils'
import { Room, Action } from './TravelerRoomPicker.types'
import * as types from './TravelerRoomPicker.constants'

export const addRoom = (rooms: Room[], action: Action): Room[] => [
  ...rooms,
  {
    roomNumber: action.payload.roomNumber,
    adults: 1,
    children: 0,
  },
]

export const removeRoom = (rooms: Room[], action: Action): Room[] =>
  rooms
    .filter((r) => r.roomNumber !== action.payload.roomNumber)
    .map((r, i) => ({
      ...r,
      roomNumber: i + 1,
    }))

export const setTravelers = (rooms: Room[], action: Action): Room[] => {
  return rooms.map((r) => {
    if (r.roomNumber === action.payload.roomNumber) {
      const adults = action.payload.adults ?? 0
      const children = action.payload.children ?? 0
      // compare with previous state and call update function if children count changed
      const didChildChange = r.children !== children

      return {
        ...r,
        adults,
        children,
        childrenAges: didChildChange
          ? updateChildrenAges(r.childrenAges, children)
          : r.childrenAges,
      }
    }
    return r
  })
}

export const setChildAge = (rooms: Room[], action: Action): Room[] =>
  rooms.map((r: Room) => {
    if (
      r.roomNumber === action.payload.roomNumber &&
      action.payload.child &&
      action.payload.age !== undefined // 0 is valid
    ) {
      const childIndex = +action.payload.child.split('_')[1] // relies on key being in 'child_<Number>' format
      return {
        ...r,
        childrenAges: updateAgeOfChild(
          r.childrenAges || [],
          {
            child: action.payload.child,
            age: action.payload.age,
          },
          childIndex
        ),
      }
    }
    return r
  })

const getReducerFunc = (actionType: Action['type']) => {
  const table = {
    [types.ADD_ROOM]: addRoom,
    [types.REMOVE_ROOM]: removeRoom,
    [types.SET_TRAVELERS]: setTravelers,
    [types.SET_CHILD_AGE]: setChildAge,
  }

  const reducerFunc = table[actionType]
  if (!reducerFunc) {
    throw new Error(`Unhandled action type: ${actionType}`)
  }

  return reducerFunc
}

export const roomReducer = (rooms: Room[], action: Action): Room[] =>
  getReducerFunc(action.type)(rooms, action)
