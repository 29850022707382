export const ADD_ROOM = 'ADD_ROOM'
export const REMOVE_ROOM = 'REMOVE_ROOM'
export const SET_TRAVELERS = 'SET_TRAVELERS'
export const SET_CHILD_AGE = 'SET_CHILD_AGE'

export type RoomPickerActionType =
  | typeof ADD_ROOM
  | typeof REMOVE_ROOM
  | typeof SET_TRAVELERS
  | typeof SET_CHILD_AGE

export const MAX_TRAVELERS_PER_BOOKING = 14
export const MAX_TRAVELERS_PER_ROOM = 7
export const MAX_ADULTS_PER_ROOM = 7
export const MAX_CHILDREN_PER_ROOM = 6
