import { z } from 'zod'
import { DestinationSchema } from '../location'

const childAge = z.object({
  child: z.string(),
  age: z.number(),
})

const hotelResultsRooms = z.object({
  adults: z.number(),
  children: z.number(),
  childrenAges: z.array(childAge).optional(),
  roomNumber: z.number(),
})

export const HotelResultsSchema = z
  .object({
    searchString: z.string().optional(),
    rooms: z.array(hotelResultsRooms).min(1),
    startDateTime: z.date(),
    endDateTime: z.date(),
    destination: DestinationSchema.required(),
  })
  .refine((data) => data.startDateTime < data.endDateTime, {
    path: ['endDateTime'],
  })

export type HotelResults = z.infer<typeof HotelResultsSchema>
