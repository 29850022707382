import { useMemo } from 'react'
import { SafeParseReturnType } from 'zod'
import {
  HotelResults,
  RoomSearchParam,
  HotelSearchParams,
  HotelSearchParamsSchema,
} from '@reward-platform/ancillaries-schemas/hotel'

const parseQueryRoom = (room: RoomSearchParam) => ({
  roomNumber: parseFloat(room.roomNumber),
  adults: parseFloat(room.adults),
  children: parseFloat(room.children),
  childrenAges: room.childrenAges?.map((child) => ({
    child: child.child,
    age: parseFloat(child.age),
  })),
})

export function useQueryStateToFormState(queryState: HotelSearchParams): HotelResults {
  return useMemo(
    () => ({
      destination: queryState.destination,
      rooms: queryState.rooms.map(parseQueryRoom),
      endDateTime: new Date(queryState.dates.endDateTime),
      startDateTime: new Date(queryState.dates.startDateTime),
    }),
    [queryState]
  )
}

export function useParseQueryStateToFormState(
  queryState: unknown
): SafeParseReturnType<HotelSearchParams, HotelResults> {
  return useMemo(() => {
    const queryToFormState = HotelSearchParamsSchema.transform((query) => ({
      destination: query.destination,
      rooms: query.rooms.map(parseQueryRoom),
      endDateTime: new Date(query.dates.endDateTime),
      startDateTime: new Date(query.dates.startDateTime),
    }))
    return queryToFormState.safeParse(queryState)
  }, [queryState])
}
